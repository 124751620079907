import simg from "../images/services/marketSearch.jpg";
import simg2 from "../images/services/marketSearch.jpg";
import simg3 from "../images/services/marketSearch.jpg";
import simg4 from "../images/services/marketSearch.jpg";
import simg5 from "../images/services/marketSearch.jpg";
import simg6 from "../images/services/marketSearch.jpg";

const Services = [
  {
    Id: "1",
    sImg: simg,
    sTitle: "Investigación de Mercado",
    slug: "investigacion-de-mercado",
    description:
      "Nuestro equipo realiza un análisis exhaustivo del mercado en Latinoamérica, identificando oportunidades de inversión que se alineen con tus metas y sectores de interés",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-gift",
  },
  {
    Id: "2",
    sImg: simg2,
    sTitle: "Estrategia de Inversión",
    slug: "estrategia-de-inversion",
    description:
      "Desarrollamos una estrategia de inversión personalizada basada en tus necesidades y en las oportunidades identificadas durante la investigación.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-suitcase",
  },
  {
    Id: "3",
    sImg: simg3,
    sTitle: "Negociación y Due Diligence",
    slug: "negociacion-due-diligence",
    description:
      "Garantizamos que todas las transacciones cumplan con las leyes y regulaciones locales e internacionales. Trabajamos para optimizar los aspectos financieros de la inversión y maximizar el retorno de la misma.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-stats",
  },
  {
    Id: "4",
    sImg: simg4,
    sTitle: "Asesoramiento Legal",
    slug: "asesoramiento-legal",
    description:
      "Colaboramos con expertos legales para garantizar que todas las transacciones cumplan conforme a las regulaciones locales e internacionales.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-start",
  },
  {
    Id: "5",
    sImg: simg5,
    sTitle: "Asesoramiento Financiero",
    slug: "asesoramiento-financiero",
    description:
      "Ofrecemos asesoramiento financiero para maximizar el rendimiento y minimizar los riesgos asociados con la inversión",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-chess-piece",
  },
  {
    Id: "6",
    sImg: simg6,
    sTitle: "Supervisión Continua",
    slug: "supervision-continua",
    description:
      "Mantenemos una supervisión constante del proyecto, interviniendo proactivamente para abordar desafíos y garantizar el éxito continuo.",
    des2: "Lacus, etiam sed est eu tempus need Temer diam congue laoret .",
    des3: "One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities",
    icon: "flaticon-green-energy",
  },
];

export default Services;
