import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import Logo from "../../logo.svg";
import services from "../../images/services/services.jpg";
const ServicePage = () => {
  const image = {
    src: services,
    alt: "gente trabajando con ordenadores",
  };
  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} Logo={Logo} />
      <PageTitle pageTitle={"Servicios"} pagesub={"Servicios"} image={image} />
      <ServiceSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePage;
