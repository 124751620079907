import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Error from "../../components/404/404";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import error from "../../images/error.jpg";
const ErrorPage = () => {
  const image = {
    src: error,
    alt: "Persona triste",
  };
  return (
    <Fragment>
      <Navbar hclass={"header-style-3"} />
      <PageTitle pageTitle={"404"} pagesub={"404"} image={image} />
      <Error />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ErrorPage;
