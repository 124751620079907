import React from "react";

const Features = (props) => {
  return (
    <section className="features-section">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="feature-grids clearfix">
              <div className="grid">
                <div className="icon">
                  <i className="fi flaticon-sheriff"></i>
                </div>

                <h3> International Business consulting</h3>
                {/* <p>
                  Peacefully between its four familiar walls. A collection of
                  textile samples lay spread out on the table
                </p> */}
              </div>
              <div className="grid">
                <div className="icon">
                  <i className="fi flaticon-diamond"></i>
                </div>
                <h3>
                  Investigación de mercado y Análisis de sectores y tendencias
                </h3>
                {/* <p>
                  Nuestro equipo realiza un análisis exhaustivo del mercado en
                  Latinoamérica, identificando oportunidades de inversión que se
                  alineen con tus metas y sectores de interés.
                </p> */}
              </div>
              <div className="grid">
                <div className="icon">
                  <i className="fi flaticon-idea"></i>
                </div>
                <h3>Portafolio de inversiones</h3>
                {/* <p>
                  Peacefully between its four familiar walls. A collection of
                  textile samples lay spread out on the table
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
