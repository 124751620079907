import React from "react";
import { Link } from "react-router-dom";

const Error = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="error-404-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="content clearfix">
              <div className="error">
                <h2>404</h2>
              </div>
              <div className="error-message">
                <h3>Oops! La pagina no existe!</h3>
                <p>
                  Lo sentimos, pero parece que no podemos encontrar la página
                  que solicitó. Esto puede deberse a que has escrito la
                  dirección web incorrectamente o que ha cambiado.
                </p>
                <Link onClick={ClickHandler} to="/" className="theme-btn-s2">
                  {" "}
                  Volver a la Página principal
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
