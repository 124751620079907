import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Contactpage from "../../components/Contactpage/Contactpage";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Logo from "../../images/logo.png";
import Footer from "../../components/footer/Footer";
import contact from "../../images/contact/comopodemosayudar.jpg";

const ContactPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} Logo={Logo} />
      <PageTitle
        pageTitle={"Contactanos"}
        pagesub={"Contacto"}
        image={{ src: contact, alt: "contact" }}
      />
      <Contactpage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
