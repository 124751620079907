import React from "react";

import opportunityland from "../../images/porquelatam/opportunityland.jpg";

const PorQueLatam = (props) => {
  return (
    <section className="experience-section porque">
      <div className="content-area">
        <div className="left-col">
          <img src={opportunityland} className="porquelatam" alt="" />
        </div>
        <div className="right-col">
          <h2>¿Por Qué InvestIn Latam?</h2>
          <h3>Razones para Invertir en Latinoamérica</h3>
          <ul>
            <li>
              <i className="ti-check"></i>Estabilidad económica y crecimiento
              sostenible
            </li>
            <li>
              <i className="ti-check"></i>Abundancia de recursos naturales y
              mano de obra calificada
            </li>

            <li>
              <i className="ti-check"></i>Mercados emergentes con gran potencial
              de retorno
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default PorQueLatam;
