import React from "react";
import CountUp from "react-countup";

const funFact = [
  {
    title: "7",
    subTitle: "años de experiencia",
    Symbol: "+",
    icon: "fi flaticon-projector",
  },
  {
    title: "743",
    subTitle:
      "US$ millones de inversión extranjera directa (IED) en Guatemala en 2023",
    Symbol: "+",
    icon: "fi flaticon-diamond",
  },
  {
    title: "486",
    subTitle:
      "US$ millones de inversión extranjera directa (IED) en El Salvador en 2023",
    Symbol: "+",
    icon: "fi flaticon-diamond",
  },
  {
    title: "225",
    subTitle: "proyectos greenfield en centroamerica el ultimo año",
    Symbol: "+",
    icon: "fi flaticon-medal",
  },
];

const FunFact = (props) => {
  return (
    <section className="fun-fact-section">
      <div className="container">
        <div className="row">
          <div className="col col-xs-12">
            <div className="fun-fact-grids clearfix">
              {funFact.map((funfact, fitem) => (
                <div className="grid" key={fitem}>
                  <div className="icon">
                    <div className={funfact.icon}></div>
                  </div>
                  <div className="info">
                    <h3>
                      <span>
                        <CountUp end={funfact.title} enableScrollSpy />
                      </span>
                      {funfact.Symbol}
                    </h3>
                    <p>{funfact.subTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <span id="counter" className="d-none" />
    </section>
  );
};

export default FunFact;
