import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import Services from "../../api/service";
// import ServiceTab from "../ServiceSinglePage/ServiceTab";
import MarketSearch from "../../images/services/marketSearch.jpg";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

// const SubmitHandler = (e) => {
//   e.preventDefault();
// };

const NegociacionDueDiligence = (props) => {
  const ServiceDetails = Services.find(
    (item) => item.sTitle === "Negociación y Due Diligence"
  );

  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} />
      <PageTitle
        pageTitle={ServiceDetails.sTitle}
        pagesub={ServiceDetails.sTitle}
        image={{ src: MarketSearch, alt: "Negociación y Due Diligence" }}
      />

      <section className="service-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-9 order-lg-2 order-1 col-12">
              <div className="service-single-content">
                <div className="service-pic">
                  <img src={ServiceDetails.sImg} alt="" />
                </div>
                <h2>{ServiceDetails.sTitle}</h2>
                {/* <p>
                  It wasn't a dream. His room, a proper human room although a
                  little too small, lay peacefully between its four familiar
                  walls. A collection of textile samples lay spread out on the
                  table - Samsa was a travelling salesman - and above it there
                  hung a picture that he had recently cut out of an illustrated
                  magazine and housed in a nice, gilded frame. It showed a lady
                  fitted out with a fur hat and fur boa who sat upright, raising
                  a heavy fur muff that covered the whole of her lower arm
                  towards the viewer. Gregor then turned to look out the window
                  at the dull weather. Drops
                </p>
                <p>
                  Peacefully between its four familiar walls. A collection of
                  textile samples lay spread out on the table - Samsa was a
                  travelling salesman - and above it there hung a picture that
                  he had recently cut out of an illustrated magazine and housed
                  in a nice, gilded frame. It showed a lady fitted out with a
                  fur hat and fur boa who sat upright
                </p>

                <h3>Magazine and housed in a nice, gilded frame</h3>
                <p>
                  Peacefully between its four familiar walls. A collection of
                  textile samples lay spread out on the table - Samsa was a
                  travelling salesman - and above it there hung a picture that
                  he had recently cut
                </p> */}
                {/* <div className="service-features">
                  <ul>
                    <li>
                      <i className="ti-check-box"></i>Towards the viewer gregor
                      then turned
                    </li>
                    <li>
                      <i className="ti-check-box"></i>Showed a lady fitted out
                      with a fur hat and
                    </li>
                    <li>
                      <i className="ti-check-box"></i>Turned to look out the
                      window
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <i className="ti-check-box"></i>Towards the viewer gregor
                      then turned
                    </li>
                    <li>
                      <i className="ti-check-box"></i>Showed a lady fitted out
                      with a fur hat and
                    </li>
                    <li>
                      <i className="ti-check-box"></i>Turned to look out the
                      window
                    </li>
                  </ul>
                </div> */}
                {/* <ServiceTab /> */}
                En construcción
                {/* <div className="request-service">
                  <h3>¿Interesado? Te contactaremos. </h3>
                  <form method="post" onSubmit={SubmitHandler}>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Su nombre y apellidos*"
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Su correo electrónico*"
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        id="phone"
                        placeholder="Su teléfono*"
                      />
                    </div>
                    <div className="submit-area">
                      <button type="submit">Enviar</button>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
            <div className="col col-lg-3 order-lg-1 order-2 col-12">
              <div className="service-sidebar">
                <div className="widget service-list-widget">
                  <ul>
                    <li className="current">
                      <Link to="/servicios">Todos los servicios</Link>
                    </li>
                    {Services.map((service, srv) => (
                      <li key={srv}>
                        <Link
                          onClick={ClickHandler}
                          to={`/servicios/${service.slug}`}
                        >
                          {service.sTitle}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div className="widget service-features-widget">
                  <h3>Características del servicio</h3>
                  <ol>
                    <li>Quality service guarantee</li>
                    <li>100% safe work</li>
                    <li>Money back guarantee</li>
                    <li>100% satisfied client</li>
                  </ol>
                </div>
                <div className="widget download-widget">
                  <ul>
                    <li>
                      <Link onClick={ClickHandler} to="/contact">
                        <i className="ti-file"></i>Download Brochure
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="widget contact-widget">
                  <div>
                    <h4>¿Necesita ayuda?</h4>
                    <p>
                      Covered the whole of her lower arm towards the viewer.
                      Gregor then turned to look out the ?
                    </p>
                    <p>Phone: +XXXXXXX</p>
                    <Link onClick={ClickHandler} to="/contacto">
                      Contact With Us
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default NegociacionDueDiligence;
