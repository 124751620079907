import { Fragment } from "react";
import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";

const AvisoLegal = () => {
  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} />
      <PageTitle pageTitle={"Aviso Legal"} pagesub={"Aviso Legal"} />
      <h2>En construcción</h2>
      <Footer />
    </Fragment>
  );
};
export default AvisoLegal;
