import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ProjectSection from "../../components/ProjectSection/ProjectSection";
import Footer from "../../components/footer/Footer";
import Logo from "../../logo.svg";
import oportunidades from "../../images/oportunidades/oportunidades.jpg";

const ProjectPage = () => {
  const image = {
    src: oportunidades,
    alt: "mapa de america central",
  };
  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} Logo={Logo} />
      <PageTitle
        pageTitle={"Oportunidades"}
        pagesub={"Oportunidades"}
        image={image}
      />
      <ProjectSection prClass={"projects-pg-section section-padding"} />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ProjectPage;
