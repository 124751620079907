import React from "react";

const WorkProcess = (props) => {
  return (
    <section className="work-process-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-8 offset-lg-2 col-md-10 offset-md-1">
            <div className="section-title">
              <span> Proceso de Atracción de Inversiones</span>
              <h3>
                En InvestIn Latam entendemos que cada inversión es única, y es
                por eso que ofrecemos servicios personalizados para satisfacer
                las necesidades específicas de cada inversor.
                <br />
                Nuestro proceso se desarrolla en varias etapas, garantizando una
                experiencia integral y eficiente
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col col-lg-10 offset-lg-1">
            <div className="work-process-grids clearfix">
              <div className="grid">
                <div className="icon">
                  <i className="fi flaticon-idea"></i>
                </div>
                <h3>01. Reunión Exploratoria</h3>
                <p>
                  Comenzamos con una consulta inicial para comprender tus
                  objetivos de inversión, necesidades, preferencias y tolerancia
                  al riesgo.
                </p>
              </div>
              <div className="grid">
                <div className="icon">
                  <i className="fi flaticon-diamond"></i>
                </div>
                <h3>02. Investigación de Mercado</h3>
                <p>
                  Nuestro equipo realiza un análisis exhaustivo del mercado en
                  Latinoamérica, identificando oportunidades de inversión que se
                  alineen con tus metas y sectores de interés.
                </p>
              </div>
              <div className="grid">
                <div className="icon">
                  <i className="fi flaticon-sheriff"></i>
                </div>
                <h3>03. Estrategia de Inversión</h3>
                <p>
                  Desarrollamos una estrategia de inversión personalizada basada
                  en tus necesidades y en las oportunidades identificadas
                  durante la investigación.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col col-lg-10 offset-lg-1">
            <div className="work-process-grids clearfix">
              <div className="grid">
                <div className="icon">
                  <i className="fi flaticon-idea"></i>
                </div>
                <h3>04. Negociación y Due Diligence</h3>
                <p>
                  <b>Negociación de Términos:</b> Actuamos como intermediarios
                  en las negociaciones, asegurando términos favorables para
                  ambas partes.
                  <br />
                  <b>Due Diligence:</b> Realizamos una diligencia debida
                  exhaustiva para garantizar la solidez y la viabilidad de la
                  inversión.
                </p>
              </div>
              <div className="grid">
                <div className="icon">
                  <i className="fi flaticon-diamond"></i>
                </div>
                <h3>05. Apoyo Legal y Financiero</h3>
                <p>
                  <b>Asesoramiento Legal:</b> Colaboramos con expertos legales
                  para garantizar que todas las transacciones cumplan conforme a
                  las regulaciones locales e internacionales. <br />
                  <b>Asesoramiento Financiero:</b> Ofrecemos asesoramiento
                  financiero para maximizar el rendimiento y minimizar los
                  riesgos asociados con la inversión.
                </p>
              </div>
              <div className="grid">
                <div className="icon">
                  <i className="fi flaticon-sheriff"></i>
                </div>
                <h3>06. Implementación del Proyecto</h3>
                <p>
                  <b>Supervisión Continua:</b> Después de la inversión, seguimos
                  supervisando el proyecto para asegurarnos de que se desarrolle
                  según lo planeado y para abordar cualquier desafío que pueda
                  surgir.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WorkProcess;
