import { Fragment } from "react";
import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";

const PoliticaDePrivacidad = () => {
  return (
    <Fragment>
      <Navbar hclass={"header-style-1"} />
      <PageTitle
        pageTitle={"Politica de privacidad"}
        pagesub={"Politica de privacidad"}
      />
      <h2>En construcción</h2>

      <Footer />
    </Fragment>
  );
};
export default PoliticaDePrivacidad;
