import React from "react";
import { Navigation, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Link } from "react-router-dom";
import beachBlueLATAM from "../../images/slider/beachBlueLATAM.jpg";

import { oportunidades } from "../../api/oportunidades";

const Hero = () => {
  const oportunidadSalvador = oportunidades.find(
    (item) => item.pais === "El Salvador"
  );
  const oportunidadGuatemala = oportunidades.find(
    (item) => item.pais === "Guatemala"
  );

  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, A11y, Autoplay]}
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      speed={1800}
      parallax={true}
      navigation
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
    >
      <SwiperSlide>
        <section className="hero-slider hero-style-1">
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${beachBlueLATAM})` }}
          >
            <div className="container">
              <div className="slide-title">
                <h2>¡Invierte en el Futuro de América Latina!</h2>
              </div>
              <div className="slide-text">
                <p>
                  Descubre Oportunidades de Inversión Excepcionales en
                  Latinoamérica
                </p>
              </div>
              <div className="clearfix"></div>
              <div className="slide-btns">
                <Link to="/contacto" className="theme-btn">
                  Contáctanos <span></span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </SwiperSlide>
      <SwiperSlide>
        <section className="hero-slider hero-style-1">
          <div
            className="slide-inner slide-bg-image"
            style={{ backgroundImage: `url(${oportunidadSalvador.secondimg})` }}
          >
            <div className="container">
              <div className="slide-title">
                <h2>¡Invierte en el Salvador !</h2>
              </div>
              <div className="slide-text">
                <p>
                  Descubre Oportunidades de Inversión Excepcionales en El
                  Salvador
                </p>
              </div>
              <div className="clearfix"></div>
              <div className="slide-btns">
                <Link to="/oportunidades/el-salvador" className="theme-btn">
                  Descubrir <span></span>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </SwiperSlide>
      <SwiperSlide>
        <section className="hero-slider hero-style-1">
          <div
            className="slide-inner slide-bg-image"
            style={{
              backgroundImage: `url(${oportunidadGuatemala.img})`,
            }}
          >
            <div className="container">
              <div className="slide-title">
                <h2>¡Invierte en Guatemala !</h2>
              </div>
              <div className="slide-text">
                <p>
                  Descubre Oportunidades de Inversión Excepcionales en Guatemala
                </p>
              </div>
              <div className="clearfix"></div>
              <div className="slide-btns">
                <Link to="/oportunidades/guatemala" className="theme-btn">
                  Descubrir
                </Link>
              </div>
            </div>
          </div>
        </section>
      </SwiperSlide>
    </Swiper>
  );
};

export default Hero;
