import { Link } from "react-router-dom";
import { oportunidades } from "../../api/oportunidades";

const NavOportunidades = ({ index }) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };
  const length = oportunidades.length;
  const prev = index === 0 ? length - 1 : index - 1;
  const next = index === length - 1 ? 0 : index + 1;
  return (
    <div className="prev-next-project">
      <div>
        <Link
          onClick={ClickHandler}
          to={`/oportunidades/${oportunidades[prev].slug}`}
        >
          <div className="icon">
            <i className="fi flaticon-back"></i>
          </div>
          <span>País anterior</span> <h5>{oportunidades[prev].pais}</h5>
        </Link>
      </div>
      <div>
        <Link
          onClick={ClickHandler}
          to={`/oportunidades/${oportunidades[next].slug}`}
        >
          <div className="icon">
            <i className="fi flaticon-next"></i>
          </div>
          <span>Próximo país</span> <h5>{oportunidades[next].pais}</h5>
        </Link>
      </div>
    </div>
  );
};
export default NavOportunidades;
